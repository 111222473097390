<template>
  <section title="">
    <b-card>
      <b-row>
        <vue-confirm-dialog />
        <b-card-body>
          <div style="text-align: right">
            <div style="display: flex; justify-content: space-between;">
              <div class="titulo-pagina">
                Usuários
              </div>
              <b-link
                :to="{ name: 'adm-usuario-adicionar'}"
                class="font-weight-bold"
              >
                <b-button
                  variant="outline-success"
                >
                  Adicionar Usuário
                </b-button>
              </b-link>
            </div>
          </div>
          <data-table
            class="mt-1"
            :data="data"
            :columns="columns"
            @on-table-props-changed="reloadTable"
          >
            <tbody
              slot="body"
              slot-scope="{
                // eslint-disable-next-line vue/no-template-shadow
                data }"
            >
              <tr
                v-for="item in data"
                :key="item.id"
              >
                <td
                  v-for="column in columns"
                  :key="column.name"
                >
                  <data-table-cell
                    :value="item"
                    :name="column.name"
                    :meta="column.meta"
                    :comp="column.component"
                    :classes="column.classes"
                  />
                  <slot v-if="column.label === 'Ações'">
                    <b-button
                      variant="danger"
                      class="btn-icon rounded-circle"
                      @click="DeletarDados(item)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                    <b-button
                      variant="warning"
                      class="btn-icon rounded-circle ml-1"
                      @click="toggleModal(item)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </slot>
                  <slot v-if="column.label === 'role_id'">
                    <b-badge
                      :variant="NomeStatus(item).cor"
                    >
                      {{ NomeStatus(item).msg }}
                    </b-badge>
                  </slot>
                </td>
              </tr>
            </tbody>
          </data-table>
        </b-card-body>
      </b-row>
    </b-card>
    <b-modal
      id="popupEditar"
      title="Editar Usuario"
      hide-footer
      centered
      size="lg"
    >
      <b-row>
        <b-col md="5">
          <b-form-group

            label="Nome do Usuario:"
            label-for="nome"
          >
            <b-form-input
              id="nome"
              v-model="nome"
              placeholder="Nome do Usuario"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group

            label="E-Mail:"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="email"
              placeholder="E-Mail"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5">
          <b-form-group

            label="Senha:"
            label-for="senha"
          >
            <b-form-input
              id="senha"
              v-model="senha"
              placeholder="Senha..."
              type="password"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Selecione Nivel do Usuario :"
            label-for="niveluser"
          >
            <v-select
              v-model="niveluser"
              label="name"
              placeholder="Nivel do Usuario"
              :options="opcoesuser"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            block
            @click="SalvarCliente()"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="change-pass"
      ref="my-modal"
      centered
      title="Alterar senha"
      hide-footer
    >
      <div style="color: black !important;">
        <div>Digite uma nova senha</div>
        <b-input
            v-model="senha"
          placeholder="Senha"
          type="password"
        />
        <div class="mt-1">
          Confirme a senha
        </div>
        <b-input
            v-model="csenha"
          placeholder="Confirme sua senha"
          type="password"
        />
        <b-button class="mt-1" variant="success" block @click="alterarSenha()">Alterar senha</b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>

import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'IndexClientesLista',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      dadosuser: '',
      // ------------- DADOS TABELA -------- \\
      url: 'admin/buscar_usuarios',
      data: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      columns: [
        {
          label: 'nome',
          name: 'name',
          orderable: true,
        },
        {
          label: 'e-mail',
          name: 'email',
          orderable: true,
        },
        {
          label: 'Nivel',
          name: 'role_name',
          orderable: true,
        },
        {
          label: 'Ações',
          orderable: false,
        },
      ],
      // -------------------------- Dados Banco -------------------------- \\
      nome: null,
      email: null,
      senha: '',
      csenha: '',
      niveluser: null,
      opcoesuser: [],
    }
  },
  async created() {
    this.$loading(true)
    await this.buscarUsuarios(this.url)
    // -------------- Busca Usuarios -------------- \\
    // await this.$http.get('admin/user').then(usuario => {
    //   const usuarioBanco = usuario.data.data
    //   const user = []
    //   usuarioBanco.forEach(u => {
    //     user.push({
    //       value: u.id,
    //       name: u.nome,
    //     })
    //   })
    //   this.opcoesuser = user
    // })
  },
  methods: {
    async alterarSenha() {
      if (this.senha.length < 6) {
        this.$toast.error('Ops sua senha é muito curta')
        return
      }
      if (this.csenha !== this.senha) {
        this.$toast.error('Ops as senhas não conferem')
        return
      }
      const obj = {
        password: this.senha,
      }
      await this.$http.put(`admin/users/${this.dadosuser.id}`, obj)
        .then(resp => {
          if (resp.data.error) {
            this.$toast.error(resp.data.error)
            return
          }
          this.$toast.success('Senha atualizada com sucesso!')
          this.$refs['my-modal'].toggle('#toggle-btn')
          this.senha = ''
          this.csenha = ''
        })
    },
    toggleModal(dados) {
      this.dadosuser = dados
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.buscarUsuarios(this.url, tableProps)
    },
    async buscarUsuarios(url = this.url, options = this.tableProps) {
      await this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
        this.$loading(false)
      })
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    SalvarCliente() {
      const obj = {
        nome: this.nome,
        email: this.email,
        password: this.senha,
      }
      // --------------------- FUNÇÃO DE EDITAR ---------------------------- //
      this.$http.put(`admin/criar_usuario/${this.id}`, obj).then(resp => {
        if (resp.data.success) {
          this.Notificacao('success', resp.data.success)
        }
        if (resp.data.error) {
          this.Notificacao('danger', resp.data.error)
        }
        this.$bvModal.hide('popupEditar')
        this.reloadTable()
      })
    },
    NomeStatus(item) {
      if (item.niveluser === 0) {
        return { cor: 'secondary', msg: 'Inativo' }
      }
      if (item.niveluser === 1) {
        return { cor: 'success', msg: 'Admin' }
      }
      if (item.niveluser === 2) {
        return { cor: 'success', msg: 'Encarregado' }
      }
      if (item.niveluser === 3) {
        return { cor: 'primary', msg: 'Planejador' }
      }
      if (item.niveluser === 4) {
        return { cor: 'success', msg: 'Coordenador' }
      }
      if (item.niveluser === 5) {
        return { cor: 'warning', msg: 'Gerente' }
      }
      if (item.niveluser === 6) {
        return { cor: 'danger', msg: 'Diretor' }
      }
      return { cor: 'danger', msg: 'Error' }
    },
    EditarProjeto(item) {
      localStorage.setItem('edituser', JSON.stringify(item))
      const user = JSON.parse(localStorage.getItem('edituser'))
      this.id = user.id
      this.nome = user.nome
      this.email = user.email
      this.senha = user.password
    },
    async DeletarDados(item) {
      this.$confirm(
        {
          message: 'Tem certeza que deseja Excluir?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`admin/users/${item.id}`)
              this.Notificacao('success', 'Usuario Removido com sucesso')
              this.reloadTable()
            }
          },
        },
      )
    },
  },
}
</script>

<style scoped>
</style>
